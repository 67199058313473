import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../assets/images/hayle_logo.png";
import { HayleButton } from "../../styles/Button";

function Header() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ boxShadow: 3, px: { xs: 2, md: 6 }, py: 1 }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          component="img"
          sx={{
            maxHeight: { xs: 50, md: 55 },
            maxWidth: { xs: 50, md: 55 },
            padding: 1,
          }}
          alt="logo"
          src={logo}
        />
        <Stack direction="row" alignItems="center">
          <Typography variant="h1">hayle</Typography>
          <Typography variant="body2">&trade;</Typography>
        </Stack>
      </Stack>
      <Link to={`/hire-us`} style={{ textDecoration: "initial" }}>
        <HayleButton>Hire Us</HayleButton>
      </Link>
    </Stack>
  );
}

export default Header;
