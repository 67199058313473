import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

const HirePage1 = (props) => {
  const { handleFormFieldChange, formFields } = props;
  const theme = useTheme();

  return (
    <Stack
      spacing={{ xs: 2, md: 3 }}
      alignItems="flex-start"
      px={{ xs: 0, md: 14 }}
    >
      <div>
        <Typography
          variant="contentSubHeader"
          color={theme.palette.secondary.contrastText}
          align="left"
        >
          Make your mark in the{" "}
          <span style={{ color: theme.palette.primary.main }}>
            digital world
          </span>
          .
        </Typography>
        <div
          style={{
            content: '" "',
            width: "85%",
            borderBottom: "3px solid " + theme.palette.primary.main,
            marginTop: "10px",
          }}
        />
      </div>
      <Typography variant="body2" align="left">
        If you would like to discuss about your next project, please feel free
        to fill out this form or email us at{" "}
        <span style={{ color: theme.palette.primary.main }}>
          hello@hayle.ca
        </span>
      </Typography>
      <FormControl sx={{ pl: { xs: 4, md: 0 } }}>
        <RadioGroup
          aria-labelledby="page-one-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(e) => handleFormFieldChange(e, "subject")}
          value={formFields.subject}
        >
          <FormControlLabel
            value="Simple Website"
            control={<Radio sx={{ p: { xs: "4px", md: "9px" } }} />}
            label="Simple Website"
            disableTypography
            sx={{
              typography: theme.typography.button,
              textTransform: "none",
              fontSize: "18pt",
            }}
          />
          <FormControlLabel
            value="Business Website"
            control={<Radio sx={{ p: { xs: "4px", md: "9px" } }} />}
            label="Business Website"
            disableTypography
            sx={{
              typography: theme.typography.button,
              textTransform: "none",
              fontSize: "18pt",
            }}
          />
          <FormControlLabel
            value="eCommerce Web Application"
            control={<Radio sx={{ p: { xs: "4px", md: "9px" } }} />}
            label="eCommerce Web Application"
            disableTypography
            sx={{
              typography: theme.typography.button,
              textTransform: "none",
              fontSize: "18pt",
            }}
          />
          <FormControlLabel
            value="Other"
            control={<Radio sx={{ p: { xs: "4px", md: "9px" } }} />}
            label="Other"
            disableTypography
            sx={{
              typography: theme.typography.button,
              textTransform: "none",
              fontSize: "18pt",
            }}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default HirePage1;
