import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import bulletIcon from "../../assets/images/bullet_icon.png";
import briefcase from "../../assets/images/briefcase_icon.png";
import fire from "../../assets/images/fire_icon.png";
import { HayleButton } from "../../styles/Button";
import { Link } from "react-router-dom";

const SubscriptionBlock = styled("div")(({ theme }) => ({
  padding: 8,
  borderRadius: theme.borderValues.radius,
  borderWidth: 0,
}));

function SubscriptionPlan(props) {
  const { subscriptionInfo, featuresList, numOfMonthsPay } = props;
  const theme = useTheme();
  const backgroundColor =
    subscriptionInfo.backgroundShade === 2
      ? theme.palette.secondary.main
      : "#FFF";
  const secondColour =
    subscriptionInfo.backgroundShade === 2
      ? "#FFF"
      : theme.palette.secondary.main;
  const pay = (subscriptionInfo.payPerMonth * numOfMonthsPay).toLocaleString(
    "en"
  );
  const titleIcon = subscriptionInfo.icon === "fire" ? fire : briefcase;

  return (
    <SubscriptionBlock sx={{ p: 4, backgroundColor: backgroundColor }}>
      <Stack spacing={2} alignItems="stretch">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            component="img"
            height={{ xs: "1.2em", md: "1.5em" }}
            alt={subscriptionInfo.icon}
            src={titleIcon}
          />
          <Typography
            variant="contentHeader"
            align="left"
            textTransform="uppercase"
          >
            {subscriptionInfo.title}
          </Typography>
        </Stack>
        <Stack alignItems="flex-start">
          <Typography variant="body2" align="left">
            Payment plans from
          </Typography>
          <Typography variant="subtitle" fontWeight="bold" align="left">
            CAD ${pay}/{numOfMonthsPay === 1 ? "month" : "year"}
          </Typography>
          <Typography variant="body2" align="left">
            with 50% down-payment on firm quote.
          </Typography>
        </Stack>
        <Typography variant="body2" align="left">
          <span style={{ fontWeight: "bold" }}>For a limited time</span>
          {", " + subscriptionInfo.description1}
        </Typography>
        <Typography variant="body2" align="left">
          {subscriptionInfo.description2}
        </Typography>
        <Stack spacing={2} py={2} borderTop={"3px solid " + secondColour}>
          {featuresList.map((feature, i) => (
            <Stack direction="row" alignItems="center" spacing={1} key={i}>
              <Box
                component="img"
                height="2em"
                alt="bullet icon"
                src={bulletIcon}
                style={{
                  opacity:
                    i < subscriptionInfo.numFeaturesToHighlight ? 1 : 0.3,
                }}
              />
              <Typography
                variant="contentSubHeader"
                align="left"
                color={
                  i < subscriptionInfo.numFeaturesToHighlight
                    ? theme.typography.contentSubHeader.color
                    : "#C0C0C0"
                }
              >
                {feature}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack direction="row">
          <Link
            to={"/hire-us"}
            state={{ jobType: subscriptionInfo.jobType, page: 2 }}
          >
            <HayleButton sx={{ minHeight: 35, minWidth: 150 }}>
              Get Started
            </HayleButton>
          </Link>
        </Stack>
      </Stack>
    </SubscriptionBlock>
  );
}

export default SubscriptionPlan;
