import { IconButton, Stack, Typography } from "@mui/material";
import { LinkedIn, Facebook, Instagram, Twitter } from "@mui/icons-material/";

function Footer() {
  return (
    <Stack px={{ xs: 3, md: 6 }} mt={{ xs: 0, md: 10 }}>
      <Typography variant="button" textTransform="uppercase">
        Join our social community
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={{ xs: 0, md: 1 }}
        pb={{ xs: 1, md: 2 }}
      >
        <IconButton
          color="button"
          aria-label="Facebook"
          component="a"
          href="https://www.facebook.com/HayleTeam/"
          target="_blank"
        >
          <Facebook />
        </IconButton>
        <IconButton
          color="button"
          aria-label="Instagram"
          component="a"
          href="https://www.instagram.com/hayleteam/"
          target="_blank"
        >
          <Instagram />
        </IconButton>
        <IconButton
          color="button"
          aria-label="LinkedIn"
          component="a"
          href="https://ca.linkedin.com/company/hayleteam/"
          target="_blank"
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          color="button"
          aria-label="Twitter"
          component="a"
          href="https://twitter.com/hayleteam/"
          target="_blank"
        >
          <Twitter />
        </IconButton>
      </Stack>
      <Typography variant="body2" py={2} sx={{ borderTop: "3px solid black" }}>
        Copyright &copy; 2023 Hayle Inc. All rights reserved.
      </Typography>
    </Stack>
  );
}

export default Footer;
