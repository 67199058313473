import { Stack, Typography } from "@mui/material";
import PaymentEcommerce from "./PaymentEcommerce";
import PaymentPlanOptions from "./PaymentPlanOptions";

function PaymentSection() {
  return (
    <Stack
      spacing={{ xs: 4, md: 8 }}
      px={{ xs: 2, md: 6 }}
      py={{ xs: 5, md: 10 }}
    >
      <Stack spacing={{ xs: 2, md: 4 }} px={{ xs: 2, md: 6 }}>
        <Typography variant="h2">Payment Plans</Typography>
        <Typography variant="h1">
          Make your mark in the digital world.
        </Typography>
        <Typography variant="body2" px={{ xs: 0, md: 15 }}>
          We are a team of creative individuals who are experts at capturing the
          essence of your brand and communicating that message effectively.
        </Typography>
      </Stack>
      <PaymentPlanOptions />
      <PaymentEcommerce />
    </Stack>
  );
}

export default PaymentSection;
