import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#5B91FE",
      brighter: "#42CEFF",
      purpler: "#7850FF",
      contrastText: "white",
    },
    secondary: {
      main: "#EEF4FF",
      contrastText: "#1B1C1E",
    },
    grounding: {
      main: "#F5F5F5",
      blue1: "#ECFAFF",
      blue2: "#DEE9FE",
      blue3: "#E4DCFF",
    },
    button: {
      main: "#2B2B2B",
    },
    error: {
      main: "#FF4270",
    },
    success: {
      main: "#90EE90",
    },
    shade: {
      dark: "#181033",
      medium: "#F8F8FF",
      light: "#FBFBFB",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
      fontWeight: "light",
      fontSize: "14pt",
      "@media (max-width:600px)": {
        fontSize: "10pt",
      },
      color: "#1D1D1B",
    },
    body2: {
      fontFamily: "Poppins",
      fontWeight: "light",
      fontSize: "11pt",
      "@media (max-width:600px)": {
        fontSize: "9pt",
      },
      color: "#1D1D1B",
    },
    h1: {
      fontFamily: "futura-pt, sans-serif",
      fontSize: "34pt",
      "@media (max-width:600px)": {
        fontSize: "20pt",
      },
      fontWeight: "bold",
      fontStyle: "normal",
      color: "#1B1C1E",
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "12pt",
      "@media (max-width:600px)": {
        fontSize: "10pt",
      },
      color: "#5B91FE",
    },
    button: {
      fontFamily: "futura-pt, sans-serif",
      fontWeight: "bold",
      fontSize: "14pt",
      "@media (max-width:600px)": {
        fontSize: "11pt",
      },
      color: "#2B2B2B",
    },
    title: {
      fontFamily: "futura-pt, sans-serif",
      fontWeight: "bold",
      fontSize: "60pt",
      "@media (max-width:600px)": {
        fontSize: "40pt",
      },
      color: "#5B91FE",
    },
    subtitle: {
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: "24pt",
      "@media (max-width:600px)": {
        fontSize: "15pt",
      },
      color: "#1B1C1E",
    },
    contentSubHeader: {
      fontFamily: "Raleway",
      fontSize: "12pt",
      "@media (max-width:600px)": {
        fontSize: "10pt",
      },
      fontWeight: "bold",
      color: "#1F1F1F",
    },
    contentHeader: {
      fontFamily: "Raleway",
      fontSize: "14pt",
      "@media (max-width:600px)": {
        fontSize: "11pt",
      },
      fontWeight: "bold",
      color: "#1F1F1F",
    },
  },
  borderValues: {
    radius: "25px",
    border: "10px solid",
  },
});
