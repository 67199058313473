import { Box, Modal, Stack, Typography, useTheme } from "@mui/material";
import logo from "../../assets/images/hayle_logo.png";

const LoadingModal = (props) => {
  const theme = useTheme();
  const { handleClose, open } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-hire-us"
      aria-describedby="modal-hire-us-form"
      sx={{
        ".MuiModal-backdrop": {
          backgroundColor: "transparent",
          backdropFilter: "blur(13px)",
        },
      }}
    >
      <Stack spacing={4} alignItems="center" mt={30}>
        <Box
          component="img"
          sx={{
            maxHeight: { xs: 100, md: 200 },
            maxWidth: { xs: 100, md: 200 },
            padding: 1,
          }}
          alt="logo"
          src={logo}
        />
        <Typography variant="h1" color={theme.palette.primary.main}>
          Loading
        </Typography>
        <Typography variant="body1">
          Thank you for being patient.
        </Typography>
      </Stack>
    </Modal>
  );
};

export default LoadingModal;
