import { useTheme } from "@emotion/react";
import { Box, Stack, styled, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import SubscriptionPlan from "./SubscriptionPlan";

const subscriptionInfoStartup = {
  title: "Ignite Your Start Up",
  icon: "fire",
  payPerMonth: 495,
  description1:
    "get a single landing page developed for your needs. Annual contract starts on date of website launch.",
  description2: "Annual payments include 8 hours of web design consultations.",
  numFeaturesToHighlight: 3,
  backgroundShade: 1,
  jobType: "Simple Website",
};

const subscriptionInfoBusiness = {
  title: "Business Essentials",
  icon: "briefcase",
  payPerMonth: 1195,
  description1:
    "get a multi-page website developed for your needs. Annual cnotract starts on date of website launch.",
  description2: "Annual payments include 24 hours of web design consultations.",
  numFeaturesToHighlight: 4,
  backgroundShade: 2,
  jobType: "Business Website",
};

const featuresList = [
  "Includes Google Domain and Cloud Hosting",
  "Email Plugin via Sendgrid",
  "Website Security and Upkeep",
  "8 hours/month developer updates",
  "Content Management System Updates",
];

const ComponentBackground = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grounding.main,
  padding: 8,
  borderRadius: theme.borderValues.radius,
  borderWidth: 0,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.shade.medium,
  minHeight: "20px",
  borderRadius: theme.borderValues.radius,
  borderWidth: 0,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  backgroundColor: theme.palette.shade.medium,
  margin: 4,
  padding: "8px 50px",
  borderRadius: theme.borderValues.radius,
  borderWidth: 0,
  minHeight: "20px",

  fontFamily: theme.typography.body2,
  fontSize: "11pt",
  fontWeight: "bold",
  textTransform: "none",
  "&.Mui-selected": {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    borderWidth: 0,
  },
  "@media (max-width:600px)": {
    padding: "6px 30px",
  },
}));

function PaymentPlanOptions() {
  const theme = useTheme();
  const [isAnnual, setIsAnnual] = useState(0);

  const handleMonthToggle = (event, selectedOption) => {
    setIsAnnual(selectedOption);
  };

  return (
    <Stack spacing={{ xs: 2, md: 4 }} alignItems="center" my={10}>
      <StyledTabs
        value={isAnnual}
        onChange={handleMonthToggle}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        aria-label="monthly or annual selection"
      >
        <StyledTab label="Monthly" />
        <StyledTab label="Annually" />
      </StyledTabs>
      <ComponentBackground>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          alignItems="stretch"
        >
          <SubscriptionPlan
            subscriptionInfo={subscriptionInfoStartup}
            featuresList={featuresList}
            numOfMonthsPay={isAnnual ? 12 : 1}
          />
          <SubscriptionPlan
            subscriptionInfo={subscriptionInfoBusiness}
            featuresList={featuresList}
            numOfMonthsPay={isAnnual ? 12 : 1}
          />
        </Stack>
        <Stack p={2}>
          <Typography variant="body2">
            Annual and monthly subscription—auto renews. Plus applicable tax.{" "}
            <Box component="span" style={{ color: theme.palette.primary.main }}>
              Learn more
            </Box>{" "}
            about Hayle's terms and services.
          </Typography>
        </Stack>
      </ComponentBackground>
    </Stack>
  );
}

export default PaymentPlanOptions;
