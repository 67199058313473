import { ArrowBack } from "@mui/icons-material/";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HireUsModal from "../HireUsModal/HireUsModal";
import LoadingModal from "../LoadingModal/LoadingModal";
import ErrorModal from "../ErrorModal/ErrorModal";
import HirePage1 from "./HirePage1";
import HirePage2 from "./HirePage2";
import HirePage3 from "./HirePage3";
import "./HireUsForm.css";

const HireUsForm = (props) => {
  // handle state passing through Link component
  const location = useLocation();

  const getJobType = () => {
    let jobType;
    if (!location.state) {
      jobType = "Simple Website";
    } else {
      jobType = location.state.jobType;
    }
    return jobType;
  };

  const getPage = () => {
    let page;
    if (!location.state) {
      page = 1;
    } else {
      page = location.state.page;
    }
    return page;
  };

  const theme = useTheme();
  const [formFields, setFormFields] = useState({
    subject: getJobType(),
    businessName: "",
    email: "",
    phone: "",
    companyName: "",
    url: "",
    otherDetails: "",
  });
  const [currPage, setCurrPage] = useState(getPage());
  const [pageHasErr, setPageHasErr] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const sendEmail = async () => {
    await axios.post(process.env.REACT_APP_SENDGRID_ENDPOINT, null, {
      params: {
        to: formFields.email,
        name: formFields.businessName,
        phoneNumber: formFields.phone,
        message: formFields.otherDetails,
        url: formFields.url,
        product: formFields.subject,
        company: formFields.companyName,
      },
    });
    // for testing errors
    // await axios.post("hello", null, {
    //   params: {
    //     to: formFields.email,
    //     name: formFields.businessName,
    //     phoneNumber: formFields.phone,
    //     message: formFields.otherDetails,
    //     url: formFields.url,
    //     product: formFields.subject,
    //     company: formFields.companyName,
    //   },
    // });
  };

  const handleFormFieldChange = (event, fieldName) => {
    const newFields = { ...formFields };
    newFields[fieldName] = event.target.value;
    setFormFields(newFields);
  };

  const handleNext = async () => {
    // don't go to next page if required inputs not valid
    if (!pageHasErr) {
      // show next page
      if (currPage === 1) setCurrPage(2);
      else if (currPage === 2) setCurrPage(3);
      else {
        // send email and redirect to home
        try {
          setOpenLoading(true);
          await sendEmail();
          setOpenSuccess(true);
          setOpenLoading(false);
        } catch (error) {
          setOpenError(true);
        }
      }
    }
  };

  const handleBack = () => {
    // show next page
    if (currPage === 2) {
      setPageHasErr(false);
      setCurrPage(1);
    } else if (currPage === 3) {
      setPageHasErr(false);
      setCurrPage(2);
    }
  };

  const showPage = () => {
    if (currPage === 3)
      return (
        <HirePage3
          handleFormFieldChange={handleFormFieldChange}
          formFields={formFields}
          setHasError={setPageHasErr}
        />
      );
    else if (currPage === 2)
      return (
        <HirePage2
          handleFormFieldChange={handleFormFieldChange}
          formFields={formFields}
          setHasError={setPageHasErr}
        />
      );
    else
      return (
        <HirePage1
          handleFormFieldChange={handleFormFieldChange}
          formFields={formFields}
        />
      );
  };

  return (
    <Stack
      spacing={{ xs: 2, md: 4 }}
      alignItems="flex-start"
      m="auto"
      p={{ xs: 4, md: 10 }}
    >
      <HireUsModal open={openSuccess}/>
      <LoadingModal open={openLoading}/>
      <ErrorModal open={openError}/>
      <div>
        <Typography
          variant="title"
          color={theme.palette.secondary.contrastText}
          sx={{ position: "relative" }}
        >
          Hire Us
        </Typography>
        <div
          style={{
            content: '" "',
            width: "70%",
            borderTop: "6px solid " + theme.palette.primary.main,
          }}
        />
      </div>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "space-between", md: "center" }}
        sx={{ height: { xs: "100%", md: "40vh" }, overflowAnchor: "none" }}
      >
        {showPage()}
        <Stack
          direction={{ xs: "row", md: "column" }}
          justifyContent="flex-end"
        >
          <div className={"radial-progress-bar progress-" + currPage}>
            <IconButton
              color="button"
              aria-label="next-page"
              onClick={handleNext}
              sx={{
                p: { xs: "8px", md: "16px" },
                border: "1px solid #5B91FE",
                width: { xs: "100px", md: "150px" },
                height: { xs: "100px", md: "150px" },
              }}
            >
              <div className="inner-circle">
                {/* <Box
                    component="img"
                    alt="box"
                    src={boxbox}
                    sx={{ p: "23px", width: "70px", height: "70px" }}
                  /> */}
                <Box
                  component="div"
                  sx={{
                    width: { xs: "50px", md: "70px" },
                    height: { xs: "50px", md: "70px" },
                    borderRadius: "50%",
                    backgroundColor: theme.palette.primary.main,
                    m: { xs: "16px", md: "23px" },
                    fontFamily: theme.typography.contentSubHeader,
                    color: "#fff",
                    lineHeight: { xs: "50px", md: "70px" },
                  }}
                >
                  {currPage < 3 ? "Next" : "Send"}
                </Box>
              </div>
            </IconButton>
          </div>
        </Stack>
      </Stack>
      {currPage > 1 ? (
        <Stack>
          <Typography
            variant="h1"
            onClick={handleBack}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBack /> Back
          </Typography>
          <Typography variant="body2">0{currPage - 1}/02</Typography>
        </Stack>
      ) : (
        <Link to={`/`} style={{ textDecoration: "initial" }}>
          <Typography variant="h1">
            <ArrowBack /> Back to Home
          </Typography>
        </Link>
      )}
    </Stack>
  );
};

export default HireUsForm;
