import anime from "animejs/lib/anime.es.js";

export function init(currentIndex) {
  const slider = document.querySelector(".slider");
  const items = slider.querySelectorAll(".slider .item");

  let current = currentIndex;

  function anim(current, next, callback) {
    const currentImgs = current.querySelectorAll(".img");
    const nextImgs = next.querySelectorAll(".img");

    const t = 400;
    const offset = "-=" + t * 0.4;
    const imgOffset = t * 0.8;

    const tl = anime.timeline({
      easing: "easeInOutQuint",
      duration: t,
      complete: callback,
    });

    // Add children
    tl.add(
      {
        targets: currentImgs[0],
        translateY: -600,
        translateZ: 0,
        rotate: [0, "-15deg"],
        opacity: [1, 0],
        easing: "easeInCubic",
      },
      offset
    )
      .add(
        {
          targets: currentImgs[1],
          translateY: -600,
          translateZ: 0,
          rotate: [0, "15deg"],
          opacity: [1, 0],
          easing: "easeInCubic",
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: currentImgs[2],
          translateY: -600,
          translateZ: 0,
          rotate: [0, "-15deg"],
          opacity: [1, 0],
          easing: "easeInCubic",
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: currentImgs[3],
          translateY: -600,
          translateZ: 0,
          rotate: [0, "15deg"],
          opacity: [1, 0],
          easing: "easeInCubic",
        },
        "-=" + imgOffset
      )
      .add({
        targets: current,
        opacity: 0,
        visibility: "hidden",
        duration: 10,
        easing: "easeInCubic",
      })
      .add(
        {
          targets: next,
          opacity: 1,
          visibility: "visible",
          duration: 10,
        },
        offset
      )
      .add(
        {
          targets: nextImgs[0],
          translateY: [600, 0],
          translateZ: 0,
          rotate: ["15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
          // duration: t * 1.5,
          // delay: (el, i) => 10 * (i + 1),
        },
        offset
      )
      .add(
        {
          targets: nextImgs[1],
          translateY: [600, 0],
          translateZ: 0,
          rotate: ["-15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
          // duration: t * 1.5,
          // delay: (el, i) => 10 * (i + 1),
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: nextImgs[2],
          translateY: [600, 0],
          translateZ: 0,
          rotate: ["15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
          // duration: t * 1.5,
          // delay: (el, i) => 10 * (i + 1),
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: nextImgs[3],
          translateY: [600, 0],
          translateZ: 0,
          rotate: ["-15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
          // duration: t * 1.5,
          // delay: (el, i) => 10 * (i + 1),
        },
        "-=" + imgOffset
      );
  }

  let isPlaying = false;

  function updateSlider(newIndex) {
    const currentItem = items[current];
    const newItem = items[newIndex];

    function callback() {
      currentItem.classList.remove("is-active");
      newItem.classList.add("is-active");
      current = newIndex;
      isPlaying = false;
    }

    anim(currentItem, newItem, callback);
  }

  function next() {
    if (isPlaying) return;
    isPlaying = true;
    const newIndex = current === items.length - 1 ? 0 : current + 1;
    updateSlider(newIndex);
  }

  return setInterval(next, 5000);
}
