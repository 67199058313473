import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { HayleButton } from "../../styles/Button";
import iceburg from "../../assets/images/Iceburg.png";
import bulletIcon from "../../assets/images/bullet_icon.png";
import { Link } from "react-router-dom";

const featuresList = [
  "Includes Google Domain and Cloud Hosting",
  "Email Marketing via Sendgrid",
  "Web App Security and Upkeep",
  "24 hours/month developer updates",
  "Content Management System Updates",
  "Payment Gateway via Stripe",
  "Shipping API Integration",
  "Full Authentication Integration",
  "Inventory Management Integration",
  "API Integration Upon Request",
];

const ComponentBackground = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grounding.main,
  padding: 8,
  borderRadius: theme.borderValues.radius,
  borderWidth: 0,
}));

function PaymentEcommerce() {
  return (
    <ComponentBackground>
      <Stack spacing={{ xs: 2, md: 4 }} p={{ xs: 3, md: 6 }}>
        <Typography variant="contentHeader" align="left">
          CUSTOM eCOMMERCE WEB APPLICATION
        </Typography>
        <Divider />
        <Grid container spacing={{ xs: 2, md: 4 }}>
          {featuresList.map((feature, i) => (
            <Grid xs={12} md={6} textAlign="left" key={i}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  component="img"
                  height="2em"
                  alt="bullet icon"
                  src={bulletIcon}
                />
                <Typography variant="contentSubHeader">{feature}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid xs={12} md={6} textAlign="left">
            <Box
              component="img"
              p={1}
              sx={{
                maxWidth: { xs: "15em", md: "20em" },
                maxHeight: "25em",
              }}
              alt="iceburg"
              src={iceburg}
            />
          </Grid>
          <Grid xs={12} md={6} textAlign="left">
            <Stack spacing={{ xs: 2, md: 4 }} justifyContent="center">
              <Typography variant="body2">
                At Hayle, we specialize in building modern, responsive web
                applications that are optimized for search engines and designed
                to convert visitors into customers.
              </Typography>
              <Typography variant="body2">
                Our team of UI/UX designers are dedicated to creating impactful
                designs that capture the essence of your band and communicating
                that message effectively.
              </Typography>
              <Typography variant="body2">
                Our development process is collaborative and transparennt, and
                we always keep you business goals in mind as we work to create
                your eCommerce web application. We work closely with you to
                understand your business goals and target audience.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row">
          <Link
            to={"/hire-us"}
            state={{ jobType: "eCommerce Web Application", page: 2 }}
          >
            <HayleButton sx={{ minHeight: 35, minWidth: 150 }}>
              Get Started
            </HayleButton>
          </Link>
        </Stack>
      </Stack>
    </ComponentBackground>
  );
}

export default PaymentEcommerce;
