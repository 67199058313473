import { ButtonBase, styled } from "@mui/material";

export const HayleButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  padding: 8,
  minWidth: 130,
  minHeight: 35,
  borderRadius: 15,
  borderWidth: 0,
  fontFamily: theme.typography.body1,
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: theme.palette.primary.light, // placeholder
  },
  cursor: "pointer",
}));
