import {
  FormHelperText,
  Input,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import validator from "validator";

const HirePage2 = (props) => {
  const theme = useTheme();
  const { handleFormFieldChange, formFields, setHasError } = props;
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    // validate inputs first time + onchange
    validateEmail(formFields.email);
    validatePhone(formFields.phone);
    validateName(formFields.businessName);
  }, []);

  useEffect(() => {
    if (nameError || emailError || phoneError) setHasError(true);
    else setHasError(false);
  }, [nameError, emailError, phoneError, setHasError]);

  const validateName = (name) => {
    if (!validator.isEmpty(name)) setNameError(false);
    else setNameError(true);
  };

  const validateEmail = (email) => {
    if (validator.isEmail(email)) setEmailError(false);
    else setEmailError(true);
  };

  const validatePhone = (phone) => {
    if (validator.isMobilePhone(phone)) setPhoneError(false);
    else setPhoneError(true);
  };

  const handleNameChange = (e) => {
    handleFormFieldChange(e, "businessName");
    validateName(e.target.value);
  };

  const handleEmailChange = (e) => {
    handleFormFieldChange(e, "email");
    validateEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    handleFormFieldChange(e, "phone");
    validatePhone(e.target.value);
  };

  return (
    <Stack
      spacing={{ xs: 2, md: 3 }}
      alignItems="flex-start"
      px={{ xs: 0, md: 14 }}
    >
      <div>
        <Typography
          variant="contentSubHeader"
          color={theme.palette.secondary.contrastText}
          align="left"
        >
          Provide us with your{" "}
          <span style={{ color: theme.palette.primary.main }}>
            contact details
          </span>
          .
        </Typography>
        <div
          style={{
            content: '" "',
            width: "85%",
            borderBottom: "3px solid " + theme.palette.primary.main,
            marginTop: "10px",
          }}
        />
      </div>
      <Typography variant="body2" align="left">
        If you would like to discuss about your next project, please feel free
        to fill out this form or email us at{" "}
        <span style={{ color: theme.palette.primary.main }}>
          hello@hayle.ca
        </span>
      </Typography>
      <Stack
        spacing={{ xs: 0, md: 1 }}
        py={2}
        pl={{ xs: 4, md: 0 }}
        sx={{ width: "100%" }}
      >
        <Stack>
          <Typography
            variant="body2"
            fontWeight="bold"
            align="left"
            fontSize="10pt"
          >
            Name
          </Typography>
          <Input
            required
            fullWidth
            id="name-field"
            placeholder="Type your name or business name..."
            error={nameError}
            value={formFields.businessName}
            onChange={handleNameChange}
            sx={{ fontSize: "10pt", marginTop: 0 }}
          />
          <FormHelperText sx={{ textAlign: "right" }}>
            {nameError ? "Required*" : " "}
          </FormHelperText>
        </Stack>
        <Stack>
          <Typography
            variant="body2"
            fontWeight="bold"
            align="left"
            fontSize="10pt"
          >
            Email
          </Typography>
          <Input
            required
            fullWidth
            id="email-field"
            type="email"
            placeholder="Type your email..."
            error={emailError}
            value={formFields.email}
            onChange={handleEmailChange}
            sx={{ fontSize: "10pt", marginTop: 0 }}
          />
          <FormHelperText sx={{ textAlign: "right" }}>
            {emailError ? "Invalid email*" : " "}
          </FormHelperText>
        </Stack>
        <Stack>
          <Typography
            variant="body2"
            fontWeight="bold"
            align="left"
            fontSize="10pt"
          >
            Phone Number
          </Typography>
          <Input
            required
            fullWidth
            id="phone-field"
            type="tel"
            placeholder="Type your phone number..."
            error={phoneError}
            value={formFields.phone}
            onChange={handlePhoneChange}
            sx={{ fontSize: "10pt", marginTop: 0 }}
          />
          <FormHelperText sx={{ textAlign: "right" }}>
            {phoneError ? "Invalid phone number*" : " "}
          </FormHelperText>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HirePage2;
