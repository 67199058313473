import { Box, Stack, Typography, useTheme } from "@mui/material";
import Tilt from "react-parallax-tilt";
import fox from "../../assets/images/Fox.png";
import "./IntroSection.css";

function IntroSection() {
  const theme = useTheme();

  return (
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      px={{ xs: 2, md: 6 }}
      py={{ xs: 2, md: 10 }}
    >
      <Stack
        alignItems="flex-start"
        justifyContent="center"
        spacing={{ xs: 2, md: 6 }}
        p={{ xs: 2, md: 6 }}
      >
        <Typography variant="h2" align="left">
          Creative Services
        </Typography>
        <Typography variant="h1" align="left">
          Optimize your{" "}
          <Box component="span" style={{ color: theme.palette.primary.main }}>
            digital business
          </Box>{" "}
          with Hayle.
        </Typography>
        <Typography variant="body1" align="left">
          Providing your business with complete expertise in web design and
          development alongside strategic branding.
        </Typography>
      </Stack>
      <Stack alignItems="center" justifyContent="center" py={{ xs: 2, md: 6 }}>
        <Tilt
          className="parallax-effect-img"
          tiltMaxAngleX={20}
          tiltMaxAngleY={20}
          perspective={800}
          transitionSpeed={1500}
          scale={1.1}
          gyroscope={true}
        >
          <Box
            component="img"
            src={fox}
            className="inner-element"
            alt="fox"
            // maxWidth={{ xs: "5rem", md: "20rem" }}
            px="25%"
            sx={{
              transform: "translateZ(40px) scale(0.8)",
              maxWidth: { xs: "25rem", md: "30rem" },
            }}
          />
        </Tilt>
      </Stack>
    </Stack>
  );
}

export default IntroSection;
