import {
  FormHelperText,
  Input,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import validator from "validator";

const HirePage3 = (props) => {
  const theme = useTheme();
  const { handleFormFieldChange, formFields, setHasError } = props;
  const [urlError, setUrlError] = useState(false);

  useEffect(() => {
    // validate inputs first time + onchange
    validateUrl(formFields.url);
  }, [formFields.url]);

  const validateUrl = (url) => {
    if (url === "" || validator.isURL(url)) {
      setUrlError(false);
      setHasError(false);
    } else {
      setUrlError(true);
      setHasError(true);
    }
  };

  const handleNameChange = (e) => {
    handleFormFieldChange(e, "companyName");
  };

  const handleUrlChange = (e) => {
    handleFormFieldChange(e, "url");
  };

  const handleDetailsChange = (e) => {
    handleFormFieldChange(e, "otherDetails");
  };

  return (
    <Stack
      spacing={{ xs: 2, md: 3 }}
      alignItems="flex-start"
      px={{ xs: 0, md: 14 }}
    >
      <div>
        <Typography
          variant="contentSubHeader"
          color={theme.palette.secondary.contrastText}
          align="left"
        >
          Provide us with your{" "}
          <span style={{ color: theme.palette.primary.main }}>
            project details
          </span>
          .
        </Typography>
        <div
          style={{
            content: '" "',
            width: "85%",
            borderBottom: "3px solid " + theme.palette.primary.main,
            marginTop: "10px",
          }}
        />
      </div>
      <Typography variant="body2" align="left">
        If you would like to discuss about your next project, please feel free
        to fill out this form or email us at{" "}
        <span style={{ color: theme.palette.primary.main }}>
          hello@hayle.ca
        </span>
      </Typography>
      <Stack
        spacing={{ xs: 0, md: 1 }}
        py={2}
        pl={{ xs: 4, md: 0 }}
        sx={{ width: "100%" }}
      >
        <Stack>
          <Typography
            variant="body2"
            fontWeight="bold"
            align="left"
            fontSize="10pt"
          >
            Company Name
          </Typography>
          <Input
            fullWidth
            id="company-field"
            placeholder="If applicable, what's your company name?"
            value={formFields.companyName}
            onChange={handleNameChange}
            sx={{ fontSize: "10pt", marginTop: 0 }}
          />
          <FormHelperText sx={{ textAlign: "right" }}> </FormHelperText>
        </Stack>
        <Stack>
          <Typography
            variant="body2"
            fontWeight="bold"
            align="left"
            fontSize="10pt"
          >
            Current website url
          </Typography>
          <Input
            fullWidth
            id="url-field"
            type="url"
            placeholder="If applicable, what's your website URL?"
            value={formFields.url}
            error={urlError}
            onChange={handleUrlChange}
            sx={{ fontSize: "10pt", marginTop: 0 }}
          />
          <FormHelperText sx={{ textAlign: "right" }}>
            {urlError ? "Invalid URL*" : " "}
          </FormHelperText>
        </Stack>
        <Stack>
          <Typography
            variant="body2"
            fontWeight="bold"
            align="left"
            fontSize="10pt"
          >
            Pertinent details you'd like to share:
          </Typography>
          <Input
            fullWidth
            id="details-field"
            placeholder="Don't be shy..."
            value={formFields.otherDetails}
            onChange={handleDetailsChange}
            sx={{ fontSize: "10pt", marginTop: 0 }}
          />
          <FormHelperText sx={{ textAlign: "right" }}> </FormHelperText>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HirePage3;
