import "./FeaturedAnimation.css";
import { init } from "./init";
import { useEffect } from "react";
import tgiInspire from "../../assets/images/featured-tgi/TGi_inspire.jpg";
import tgiHome from "../../assets/images/featured-tgi/TGi_home.jpg";
import tgiProcess from "../../assets/images/featured-tgi/TGi_process.jpg";
import tgiSelection from "../../assets/images/featured-tgi/TGi_selection.jpg";
import tsf1 from "../../assets/images/featured-tsf/TSF_inventory.jpg";
import tsf2 from "../../assets/images/featured-tsf/TSF_home.jpg";
import tsf3 from "../../assets/images/featured-tsf/TSF_dashboard.jpg";
import tsf4 from "../../assets/images/featured-tsf/TSF_manager.jpg";
import cm1 from "../../assets/images/featured-cm/CM_dashboard.jpg";
import cm2 from "../../assets/images/featured-cm/CM_home.jpg";
import cm3 from "../../assets/images/featured-cm/CM_product.jpg";
import cm4 from "../../assets/images/featured-cm/CM_blog.jpg";
import centra1 from "../../assets/images/featured-centra-k/centra_home.jpg";
import centra2 from "../../assets/images/featured-centra-k/centra_blog.jpg";
import centra3 from "../../assets/images/featured-centra-k/centra_about.jpg";
import centra4 from "../../assets/images/featured-centra-k/centra_contact.jpg";

const FeaturedAnimation = (props) => {
  return (
    <div className="slider">
      <div className="item is-active">
        <div className="imgs">
          <div className="grid-1">
            <div className="img img-1">
              <img alt="tgi inspire page preview" src={tgiInspire} />
            </div>
            <div className="img img-2">
              <img alt="tgi home page preview" src={tgiHome} />
            </div>
          </div>
          <div className="grid-2">
            <div className="img img-3">
              <img alt="tgi process page preview" src={tgiProcess} />
            </div>
            <div className="img img-4">
              <img alt="tgi selection page preview" src={tgiSelection} />
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="imgs">
          <div className="grid-1">
            <div className="img img-1">
              <img alt="tsf preview 1" src={tsf1} />
            </div>
            <div className="img img-2">
              <img alt="tsf preview 2" src={tsf2} />
            </div>
          </div>
          <div className="grid-2">
            <div className="img img-3">
              <img alt="tsf preview 3" src={tsf3} />
            </div>
            <div className="img img-4">
              <img alt="tsf preview 4" src={tsf4} />
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="imgs">
          <div className="grid-1">
            <div className="img img-1">
              <img alt="cm preview 1" src={cm1} />
            </div>
            <div className="img img-2">
              <img alt="cm preview 2" src={cm2} />
            </div>
          </div>
          <div className="grid-2">
            <div className="img img-3">
              <img alt="cm preview 3" src={cm3} />
            </div>
            <div className="img img-4">
              <img alt="cm preview 4" src={cm4} />
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="imgs">
          <div className="grid-1">
            <div className="img img-1">
              <img alt="centra preview 1" src={centra1} />
            </div>
            <div className="img img-2">
              <img alt="centra preview 2" src={centra2} />
            </div>
          </div>
          <div className="grid-2">
            <div className="img img-3">
              <img alt="centra preview 3" src={centra3} />
            </div>
            <div className="img img-4">
              <img alt="centra preview 4" src={centra4} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="item">
        <div className="imgs">
          <div className="grid">
            <div className="img img-1">
              <img src="https://picsum.photos/seed/i/700/700" />
            </div>
            <div className="img img-2">
              <img src="https://picsum.photos/seed/j/700/700" />
            </div>
            <div className="img img-3">
              <img src="https://picsum.photos/seed/k/700/700" />
            </div>
            <div className="img img-4">
              <img src="https://picsum.photos/seed/l/700/700" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default FeaturedAnimation;
