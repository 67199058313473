import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { HayleButton } from "../../styles/Button";
import FeaturedAnimation from "../FeaturedAnimation/FeaturedAnimation";
import { init } from "../FeaturedAnimation/init";

const tgi = {
  title: "TGi Quartz & Granite",
  description: `The fastest growing stone countertop company in Ottawa took their
  sales online with a new robust user interface, enabling customers to
  fully explore a diverse engineered quartz and natural stone inventory.
  From online quoting to virtual home visualizers, TGi's daily following
  continues to grow courtesy of their new 'top of the line' digital
  profile.`,
  link: "https://tgiquartzandgranite.com/",
};

const tsf = {
  title: "TSF - The Stone Fabricator Inc.",
  description: `One of the largest business-to-business solid surface fabrication 
  facilities in Ottawa—they digitized their sales process, inventory and 
  operation—enabling partners to receive real-time updates on their countertop 
  projects. TSF continues to evolve to provide their clients with better user 
  experience with their new top of line web application.`,
  link: "https://thestonefabricator.com/",
};

const cm = {
  title: "Caducée Médical",
  description: `A French PPE fabrication facility that distributes globally—they 
  digitized their entire business model—enabling institutions to quickly order in 
  large quantities and track it online. Caducée Médical continues to grow their 
  distribution with our custom solution; from customer relationship management to 
  real-time production-line operations.`,
  link: "https://caduceemedical.com/",
};

const centrak = {
  title: "Centra-K",
  description: `A French-Canadian home inspection company that serves in the city 
  of Gatineau—they digitally establish themselves—capturing a piece of the housing 
  market. Centra continues to offer their services and keep customers well informed.`,
  link: "https://centra-k.ca/",
};

const featuredWorksList = [tgi, tsf, cm, centrak];

function FeaturedSection() {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const imgTimer = init(current);
    const timer = setInterval(() => {
      setCurrent((prev) => {
        if (prev + 1 === featuredWorksList.length) return 0;
        else return prev + 1;
      });
    }, 5000);

    // clearing interval
    return () => {
      clearInterval(imgTimer);
      clearInterval(timer);
    };
  });

  return (
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      px={{ xs: 2, md: 6 }}
      py={{ xs: 5, md: 10 }}
      sx={{ overflowAnchor: "none" }}
    >
      <Stack
        alignItems="flex-start"
        justifyContent="center"
        spacing={{ xs: 2, md: 6 }}
        pl={{ xs: 2, md: 6 }}
        sx={{ width: { xs: "100%", md: "50em" } }}
      >
        <Typography variant="h2" align="left">
          Featured Work
        </Typography>
        <Typography variant="h1" align="left">
          {featuredWorksList[current].title}
        </Typography>
        <Typography variant="body1" align="left">
          {featuredWorksList[current].description}
        </Typography>
        <HayleButton href={featuredWorksList[current].link} target="_blank">
          Explore
        </HayleButton>
      </Stack>
      <FeaturedAnimation />
    </Stack>
  );
}

export default FeaturedSection;
