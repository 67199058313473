import "./App.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import HireUsForm from "./components/HireUsForm/HireUsForm";
import { BrowserRouter, redirect, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/hire-us" element={<HireUsForm />} />
            <Route path="*" loader={() => redirect("/")} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
