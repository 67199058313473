import { Box, Modal, Stack, Typography, useTheme } from "@mui/material";
import logo from "../../assets/images/hayle_logo.png";
import { Link } from "react-router-dom";
import { HayleButton } from "../../styles/Button";

const ErrorModal = (props) => {
  const theme = useTheme();
  const { handleClose, open } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-hire-us"
      aria-describedby="modal-hire-us-form"
      sx={{
        ".MuiModal-backdrop": {
          backgroundColor: "transparent",
          backdropFilter: "blur(13px)",
        },
      }}
    >
      <Stack spacing={4} alignItems="center" mt={30}>
        <Box
          component="img"
          sx={{
            maxHeight: { xs: 100, md: 200 },
            maxWidth: { xs: 100, md: 200 },
            padding: 1,
          }}
          alt="logo"
          src={logo}
        />
        <Typography variant="h1" color="red">
          Error Sending Email.
        </Typography>
        <Typography variant="body1">
          Please email us at hello@hayle.ca
        </Typography>
        <Link to={`/`} style={{ textDecoration: "initial" }}>
          <HayleButton
            sx={{
              color: theme.palette.button.main,
              textTransform: "uppercase",
              backgroundColor: "transparent",
              border: "1px solid " + theme.palette.primary.main,
            }}
          >
            Exit
          </HayleButton>
        </Link>
      </Stack>
    </Modal>
  );
};

export default ErrorModal;
