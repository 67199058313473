import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import rocketship from "../../assets/images/Rocketship.png";
// import RocketAnimation from "../RocketAnimation/RocketAnimation";

const BorderContainer = styled(Box)(({ theme }) => ({
  // padding: 10,
  // margin: 100,
  // border: theme.borderValues.border,
  // borderRadius: theme.borderValues.radius,
  // borderColor: theme.palette.grounding.main,

  [theme.breakpoints.up("xs")]: {
    border: "5px solid",
    borderRadius: theme.borderValues.radius,
    borderColor: theme.palette.grounding.main,
  },
  [theme.breakpoints.up("md")]: {
    border: theme.borderValues.border,
    borderRadius: theme.borderValues.radius,
    borderColor: theme.palette.grounding.main,
  },
}));

function AboutSection() {
  const theme = useTheme();
  return (
    <BorderContainer
      p={{ xs: "5px", md: "10px" }}
      m={{ xs: "30px", md: "100px" }}
    >
      <Stack direction="row">
        <Stack
          alignItems="flex-start"
          py={1}
          px={25}
          sx={{ display: { xs: "none", md: "none", lg: "flex" } }}
        >
          {/* <RocketAnimation /> */}
          <img src={rocketship} alt="rocketship" />
        </Stack>
        <Stack
          alignItems="flex-start"
          justifyContent="center"
          spacing={{ xs: 1, md: 6 }}
          px={{ xs: 4, md: 6 }}
          my={{ xs: 4, md: 6 }}
        >
          <Typography variant="h2" align="left">
            About Us
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="title">hayle</Typography>
            <Typography variant="h2">&trade;</Typography>
          </Stack>
          <Typography variant="body1" align="left">
            A creative agency, a digital studio and a software R&D company join
            forces to leverage the latest technologies to design and develop
            endless possibilities.
          </Typography>
        </Stack>
      </Stack>
    </BorderContainer>
  );
}

export default AboutSection;
