import Header from "../Header/Header";
import IntroSection from "../IntroSection/IntroSection";
import AboutSection from "../AboutSection/AboutSection";
import FeaturedSection from "../FeaturedSection/FeaturedSection";
import PaymentSection from "../PaymentSection/PaymentSection";
import Footer from "../Footer/Footer";

const LandingPage = (props) => {
  return (
    <div>
      <Header />
      <IntroSection />
      <AboutSection />
      <FeaturedSection />
      <PaymentSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
